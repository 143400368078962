import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

// import the roots
import rootReducer from './redux-sagas/reducers/index';
import rootSaga from './redux-sagas/sagas/root';

const sagaMiddleware = createSagaMiddleware()

const defaultState = {
    accepted: null,
    adjustments: null,
    afp: null,
    atp: null,
    ageGroups: null,
    answers: null,
    appliedQuestions: null,
    appPlans: null,
    archived_teams: null,
    auth: {},
    autoPaySettings: null,
    balance: null,
    bracket_group_types: null,
    carriers: null,
    cart: localStorage.getItem('sportslogic.cart') ? JSON.parse(localStorage.getItem('sportslogic.cart')) : [],    
    campaigns: null,
    campaign: null,
    clips: [],    
    coaches: null,
    communities: null,
    colors: null,
    contest_type: null,
    countries: [],
    country_codes: [],
    coupons: null,
    couponTypes: null,
    collaborators: null,
    college_profile: null,
    customGroups: null,
    customAttributesTemplates: null,
    details: null,
    defaultGameDuration: localStorage.getItem('sportslogic.defaultGameDuration') ? parseInt(localStorage.getItem('sportslogic.defaultGameDuration'), 10) : 30,
    depth_charts: null,
    developer: null,
    disclaimer: null,
    divisions: null,
    division: null,
    dues: null,
    emails: null,
    events: null,
    exceptionRules: [],
    family: localStorage.getItem('sportslogic.family') ||null,
    family_lastname: localStorage.getItem('sportslogic.family.lastname') || null, 
    familyAdjustments: null,
    familyRegistration: [],
    family_subscriptions: [],
    family_videos: [],
    fees: null,
    feeTypes: null,
    fetchingData: 0,     
    fetchingSpotlight: false,
    fields: null,
    fields_layout: null,
    files: [],
    filters: null,
    fieldAvailabilityRules: [],
    flightedTeams: [],
    flights: [],
    gameDayRules: [],
    game: null,
    games: null,
    game_types: null,
    genders: [],
    generic: null,
    highlights: [],
    information: null,
    isCartOpen: false,
    isTeamSelectionOpen: false,
    isUserSelectionOpen: false,
    leads: [],
    leagues: null,
    league: null,
    leagueImage: null,
    leaguePoints: null,
    library: null,
    limits: null,
    lineups: null,
    locations: null,
    locks: null,
    loggedIn: 0,
    messages_received: null,
    messages_sent: null,
    membership_programs: null,
    medals: null,
    micro: false,
    notes: null,
    network: null,
    network_coaches: null,
    pastDues: null,
    paymentPlans: null,
    payments: null,
    permissions: null,
    processes: null,
    phones: null,
    players: null,
    points: null,
    tournamentPointsTypes: null,
    tournamentSeasons: null,
    positions: null,
    pouch: localStorage.getItem('sportslogic.pouch') ? JSON.parse(localStorage.getItem('sportslogic.pouch')) : {},
    questions: [],
    questionaries: null,
    questionGroups: null,
    questionTexts: null,
    referal: localStorage.getItem('sportslogic.referal') || null,
    report: null,
    registrations: null,
    rights: null,
    roles: null,
    roster: null,
    scout_registrations: null,
    season: {},
    seasons: null,
    selectedTeam: null,
    selectedUser: null,
    selectedAnswers: [],
    settings: null,
    sizes: null,
    slots: null,
    spotlight: null,
    spotlightOn: false,
    spread: null,
    states: [],
    statuses: [],
    teamRegistration: [],
    teams: null,
    templates: [],
    team_subscriptions: [],
    team_evaluations: [],
    time_zones: [],
    topics: null,
    tryouts: null,
    rosterTeams: null,
    title: {},
    users: null,
    user: null,
    uniform_bag: null,
    validations: null,
    validations_summary: {},
    violations: null,
    violation_types: null,
    videos: null,
    waiver: null,
    waivers: null,
    waitlists: null,
    zipCodes: [],
    zipCodesAdjustments: null
};

// TODO: understand this ?
const enhancers = compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
);
const store = createStore(
    rootReducer,
    defaultState,
    enhancers
);
//

sagaMiddleware.run(rootSaga)

export default store;
